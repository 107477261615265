/* ======================================================================== */
/* 118. widgetLogo */
/* ======================================================================== */
.widget_rhye_logo, .widget_rhye_logo
	max-width: 360px
	// .logo
	// 	margin-bottom: 2em

@media screen and (max-width: $md)
	.widget_rhye_logo, .widget_rhye_logo
		max-width: 100%
		// .logo
		// 	margin-bottom: 1em
