@import "helpers/_functions.sass"
@import "helpers/_fluid-margin.sass"
@import "helpers/_fluid-padding.sass"
@import "helpers/_fluid-property.sass"
@import "helpers/_fluid-translate-y.sass"
@import "helpers/_fluid-type.sass"
	
@mixin trans1
	transition: all 0.2s cubic-bezier(0.785, 0.135, 0.150, 0.860)
@mixin trans2
	transition: all 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860)
@mixin reset-ul
	list-style-type: none
	padding: 0
	margin: 0
@mixin shadow
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1)
@mixin small-caps
	@include fluid-type(10, 13)
	font-weight: 700
	letter-spacing: 1px
	line-height: 1.3
	text-transform: uppercase

@mixin dropcap
	float: left
	font-family: $font-secondary
	@include fluid-type(var(--dropcap-min-font-size), var(--dropcap-max-font-size))
	color: var(--dropcap-color-dark)
	line-height: var(--dropcap-line-height)
	text-transform: uppercase
	font-style: normal
	margin: 0.15em 0.25em 0 0
	&:after
		content: ''
		display: table
		clear: both
