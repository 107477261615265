/* ======================================================================== */
/* 69. sectionListThemes */
/* ======================================================================== */
[data-arts-theme-text="dark"]
  @media screen and (max-width: $sm)
    .list-projects__item
      &.blend-difference
        mix-blend-mode: initial !important
        color: initial !important
[data-arts-theme-text="light"]
  @media screen and (max-width: $sm)
    .list-projects__item
      &.blend-difference
        mix-blend-mode: #fff !important
        color: #fff !important
