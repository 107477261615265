/* ======================================================================== */
/* 86. sliderServices */
/* ======================================================================== */
.slider-services
.slider-services__wrapper-bg
.slider-services__footer
	max-width: 1180px
	// padding-left: 0
	// padding-right: 0
@media screen and (max-width: 1600px)
	.slider-services__footer
		max-width: 940px
@media screen and (max-width: $xl)
	.slider-services__footer
		max-width: 840px
@media screen and (max-width: $md)
	.slider-services__footer
