/* ======================================================================== */
/* 7. buttonSolid */
/* ======================================================================== */
.button_solid
	&.button_black
		border-color: $black
		background-color: $black
		color: #fff
		// &:hover
		// 	background-color: #fff
		// 	color: $black
