/* ======================================================================== */
/* 88. social */
/* ======================================================================== */
.social
	+reset-ul
	// margin: -4px -8px
.social__item
	display: inline-block
	transition: none
	// margin: 8px
	a
		display: flex
		align-items: center
		justify-content: center
		width: 30px
		height: 30px
		font-size: 14px
		&:hover
