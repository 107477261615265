/* ======================================================================== */
/* 13. curtain */
/* ======================================================================== */
.curtain
  // display: flex
  // flex-direction: column
  // justify-content: center
.curtain__wrapper-svg
  width: 100%
  height: 100%
.curtain__rect
  flex: 1 0 80%
  background: #000000
.curtain
  width: 100%
  height: 100%
.curtain-svg
  width: 100%
  height: 100%
.curtain-svg__curve
  visibility: hidden
// .curtain__wrapper-svg
.curtain-svg_bottom
  display: none !important
  transform: rotate(180deg) !important
