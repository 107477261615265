/* ======================================================================== */
/* 72. sectionNavProjectsThemes */
/* ======================================================================== */
[data-arts-theme-text="light"]  
  .section-nav-projects__header
    &:hover
      .section-nav-projects__link
        opacity: 1 !important
        color: #fff !important
  .section-nav-projects__subheading
    color: #fff
  .section-nav-projects__link
    opacity: .2
