/* ======================================================================== */
/* 46. menuOverlay */
/* ======================================================================== */
.menu-overlay
	+reset-ul
	> li
		display: block
		margin-top: 0.5em
		margin-bottom: 0.5em
		> a
			display: inline-block
.menu-overlay__item-wrapper
	width: 100%
	height: 100%
	// font-family: $font-secondary
.menu-overlay .sub-menu
	display: inline-flex
	flex-direction: column
	justify-content: flex-start
	position: absolute
	bottom: 0
	left: 0
	height: 100%
	width: 100%
	+reset-ul
	z-index: -1
	opacity: 0
	visibility: hidden
	> li
		display: block
		// margin-top: 0.25em
		// margin-bottom: 0.25em
		> a
			display: inline-block
			// font-family: $font-secondary
			// @include fluid-type($min_width, $max_width, $menu-overlay-sub-min-font-size, $menu-overlay-sub-max-font-size)
			// font-weight: $menu-overlay-sub-font-weight
			// line-height: $menu-overlay-sub-line-height
			padding: 8px 0

// @media screen and (max-width: $md)
// 	.menu-overlay
// 		> li
// 			> a
// 				margin-top: 0.25em
// 				margin-bottom: 0.25em
