/* ======================================================================== */
/* 29. formContacts */
/* ======================================================================== */
.form-contact_paddings
  padding: 80px

@media screen and(max-width: $md)
  .form-contact_paddings
    padding: 40px

@media screen and(max-width: $md)
  .form-contact_paddings
    padding: 40px 20px 60px
