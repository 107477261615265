/* ======================================================================== */
/* 36. headerLogo */
/* ======================================================================== */
[data-arts-header-logo="primary"]
	.logo__img-primary
		opacity: 1
		visibility: visible
	.logo__img-secondary
		opacity: 0
		visibility: hidden
[data-arts-header-logo="secondary"]
	.logo__img-primary
		opacity: 0
		visibility: hidden
	.logo__img-secondary
		opacity: 1
		visibility: visible
	.logo__text-title
		color: #fff
	.logo__text-tagline
		color: var(--color-gray-2)
