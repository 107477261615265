/* ======================================================================== */
/* 57. scroll */
/* ======================================================================== */
.smooth-scroll
	overflow: hidden
	width: 100vw
	height: 100vh
.scroll-content
[data-arts-os-animation]:not([data-arts-os-animation="animated"])
	opacity: 0
	visibility: hidden
body.elementor-editor-active [data-arts-os-animation]
	opacity: 1
	visibility: visible
.c-scrollbar
	display: none !important
[data-arts-scroll-fixed]
	width: 100% !important
	left: 0 !important
	top: 0 !important
