/* ======================================================================== */
/* 65. sectionContent */
/* ======================================================================== */
.section-content
	// p:first-letter
	// 	@include fluid-type(43, 140)
	// 	font-family: $font-secondary
	// 	line-height: 1
	// 	display: block
	// 	float: left
	// 	margin-right: 1rem
.section-content__heading, .section-content__text
	> *
		&:first-child
			margin-top: 0
	> *
		&:last-child
			margin-bottom: 0
.section-content__text
	max-width: 700px
.text-left
	.section-content__text
		margin-left: 0
		margin-right: auto
.text-center
	.section-content__text
		margin-left: auto
		margin-right: auto
.text-right
	.section-content__text
		margin-left: auto
		margin-right: 0
