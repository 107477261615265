/* ======================================================================== */
/* 44. magnetic */
/* ======================================================================== */
// .js-cursor__item-magnetic
//   position: absolute
//   display: block
//   top: 50%
//   left: 50%
//   width: 200%
//   height: 200%
//   transform: translate(-50%,-50%)
//   // border: 1px solid red
//   cursor: pointer
//   z-index: -1
// .magnetic__area
