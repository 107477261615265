/* ======================================================================== */
/* 94. tags */
/* ======================================================================== */
.tagcloud, .widget .tagcloud
	a
		display: inline-block
		+small-caps
		background-color: transparent
		padding: 8px 14px
		margin-bottom: 6px
		margin-right: 4px
		border-radius: 100px
		border: 1px solid $borders-dark
		&:hover
			// background-color: $black
			border-color: $black
			// color: #fff !important
	ul
		+reset-ul
		li
			display: inline-block
			margin-bottom: 0
