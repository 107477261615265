/* ======================================================================== */
/* 6. buttonBordered */
/* ======================================================================== */
.button_bordered
	&.button_white
		border-color: #fff
		color: #fff
		&:hover
			// background-color: #fff
			// color: $black
	&.button_black
		border-color: $black
		color: $black
		&:hover
			// background-color: $black
			// color: #fff
