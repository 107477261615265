/* ======================================================================== */
/* 45. menu */
/* ======================================================================== */
.menu
	position: relative
	+reset-ul
	margin: 0 -15px
	word-wrap: normal
	+small-caps
	> li
		display: inline-block
		&:not(:last-child)
			margin-right: 17px
		a
			display: block
			padding: 0px 15px
			// &:hover
			// 	color: $warm-white
	> li.menu-item-has-children
		> a
	.menu-item-has-children
		position: relative
		> a
			&:hover
				// opacity: 0
				// visibility: hidden
				~ ul
					opacity: 1
					visibility: visible
					transform: translate(0px, 0px)
					z-index: 50
.menu.menu_disabled
	.sub-menu
		opacity: 0 !important
		visibility: hidden !important
		transform: translate(0px, 20px) !important
.menu
	.sub-menu
		position: absolute
		top: calc(100% + 15px)
		left: 15px
		transform: translate(0px, 20px)
		+reset-ul
		// padding: 5px 0
		opacity: 0
		visibility: hidden
		+trans1
		z-index: -1
		background-color: #fff
		border-radius: 2px
		text-align: left
		box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04)
		&:hover
			opacity: 1
			visibility: visible
			transform: translate(0px, 0px)
			z-index: 50
		> li
			white-space: nowrap
			a
				padding: 10px 15px
				background-color: transparent
				border-left: 2px solid transparent
				&:hover
					background-color: #fafafa
					border-color: var(--color-dark-2)
					color: var(--color-dark-2)
		ul
			top: 0
			left: calc(100% + 1px)
			transform: translate(10px, 0px)
			opacity: 0
			visibility: hidden
// 		> li
// 			display: block
// 			white-space: nowrap
// 			a
// 				display: flex
// 				align-items: center
// 				padding: 10px 30px
// 				border-left: 2px solid transparent
// 				&:hover
// 					border-color: $sandstone
// 					background-color: $light
// 		> li.menu-item-has-children
// 			> a
// 				&:before
// 					content: '\f104'
// 					font-family: 'FontAwesome'
// 					display: inline-block
// 					vertical-align: middle
// 					margin-right: auto
// 					padding-right: 15px
// 		ul
// 			top: 0
// 			right: calc(100% + 1px)
// 			transform: translate(10px, 0)
// .sub-menu_hover
// 	> li
// 		// z-index: -1
// 		> a
// 		> .sub-menu
// 			opacity: 1
// 			visibility: visible
// 			transform: translateY(0%)
// .menu_hover
// 	> li a
// 		opacity: .3 !important
// 		&:hover, &:focus
// 			opacity: 1 !important
