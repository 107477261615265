/* ======================================================================== */
/* 79. sliderCategories */
/* ======================================================================== */
.slider-categories
	display: inline-flex
	position: relative
	&.text-right
		.slider-categories__category:not(:nth-child(1))
			left: auto
			right: 0
.slider-categories__category
	display: inline-block
	white-space: nowrap
	line-height: 1
	&:not(:nth-child(1))
		position: absolute
		top: 0
		left: 0

@media screen and (max-width: $md)
	.slider-categories
		width: 100%
		margin-top: 1em
		margin-bottom: 1em
	.slider-categories__category
		width: 100%

@media screen and(min-width: $md - 1)
	.slider-categories
		&.text-lg-right
			.slider-categories__category:not(:nth-child(1))
				left: auto
				right: 0
